// import React from "react";

// function article_1() {
// 	return {
// 		date: "7 May 2023",
// 		title: "The Benefits of Cloud Computing",
// 		description:
// 			"Cloud computing offers a range of benefits, including cost savings and increased flexibility. Find out why more businesses are turning to the cloud.",
// 		keywords: [
// 			"The Benefits of Cloud Computing",
// 			"Osanyem",
// 			"Osanyem O",
// 			"Osanyem Osadebe",
// 		],
// 		style: `
// 				.article-content {
// 					display: flex;
// 					flex-direction: column;
// 					align-items: center;
// 				}

// 				.randImage {
// 					align-self: center;
// 					outline: 2px solid red;
// 				}
// 				`,
// 		body: (
// 			<React.Fragment>
// 				<div className="article-content">
// 					<div className="paragraph">Content of article 1</div>
// 					<img
// 						src="https://picsum.photos/200/300"
// 						alt="random"
// 						className="randImage"
// 					/>
// 				</div>
// 			</React.Fragment>
// 		),
// 	};
// }

const myArticles = []; // Add articles to list if you need to return any.

export default myArticles;
